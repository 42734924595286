.footers {
  
  padding: 1px; 
  position: absolute;
 width: 98% ; 
 
  border-top: 2px solid #ddd;
  bottom: 0px; 
  z-index: 5;
 
  margin-bottom: 0px;
  padding-bottom: 0px;
  height: 28px; 
 overflow: hidden;
 
}


.footer .container {
   width: 100%;
   text-align: end;
  max-width: 1200px; 
  margin: 0 auto;
  padding: 0px ;
  margin-left: 1px; 
  
}


.footer .row {
  display: flex;       
  flex-wrap: wrap;    
  align-items: center; 
  justify-content: center; 
}


.footer .col-1,
.footer .col-8,
.footer .col-3 {
  padding: 0px;     
}


.footer .col-1 {
  flex: 0 0 8.33%;     
}

.footer .col-8 {
  flex: 0 0 66.67%;    
}

.footer .col-3 {
  flex: 0 0 25%;        
}


@media (max-width: 767px) {
  .footer .col-1,
  .footer .col-8,
  .footer .col-3 {
      flex: 0 0 100%;    
      text-align: center; 
  }
}





