    /* General container styling */
    .calendar-container {
        max-width: 100%;
        margin: 20px auto;
        padding: 20px;
        border: 2px solid #ccc;
        border-radius: 12px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        background: #f8f9fa;
    }
    
    .calendar-title {
        text-align: center;
        font-family: 'Arial', sans-serif;
        color: #333;
        margin-bottom: 20px;
        font-size: 1.5rem;
    }
    
    /* Tile customization */
    .react-calendar {
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
        border: none;
        font-family: 'Arial', sans-serif;
        line-height: 1.125em;
    }
    
    .react-calendar__tile {
        padding: 10px;
        text-align: center;
        border-radius: 8px;
        font-size: 20px;
        transition: all 0.3s ease;
        position: relative;
    }
    
    /* Hover effect for tiles */
    .react-calendar__tile:hover {
        background: #ddd;
        transform: scale(1.1);
    }
    
    /* Tile content styles */
    .tile-content {
        font-size: 10px;
        line-height: 1.2;
        color: #000000;
    }
    
    /* Attendance tile */
    .tile-attendance {
        background:green;
        color: #000000;
        font-weight: bold;
    }
    
    /* Holiday tile */
    .tile-holiday {
        background:orange;
        color: #000000;
        font-weight: bold;
    }
    
    /* Leave tile */
    .tile-leave {
        background: red;
        color: #070707;
        font-weight: bold;
    }
    
    /* Week Off tile */
    .tile-content.week-off {
        color: #6c757d;
        font-style: italic;
        font-size: 12px;
    }
    
    /* Calendar navigation */
    .react-calendar__navigation button {
        color: #333;
        font-size: 1rem;
        font-weight: bold;
        margin: 2px;
        border: none;
        border-radius: 4px;
        background: #f0f0f0;
    }
    
    .react-calendar__navigation button:hover {
        background: #007bff;
        color: white;
    }
    .react-calendar__month-view__weekdays__weekday:nth-child(1) { /* Sunday */
        border-color: #ff0000;
        background: rgba(255, 0, 0, 0.1); /* Light red background */
        color: #ff0000;
      }
      .react-calendar__month-view__weekdays__weekday:nth-child(2) { /* Monday */
        border-color: #ff9800;
        background: rgba(255, 152, 0, 0.1); /* Light orange background */
        color: #ff9800;
      }

      .react-calendar__month-view__weekdays__weekday:nth-child(3) { /* Tuesday */
        border-color: #ffc107;
        background: rgba(255, 193, 7, 0.1); /* Light yellow background */
        color: #ffc107;
      }
      
      .react-calendar__month-view__weekdays__weekday:nth-child(4) { /* Wednesday */
        border-color: #4caf50;
        background: rgba(76, 175, 80, 0.1); /* Light green background */
        color: #4caf50;
      }
      
      .react-calendar__month-view__weekdays__weekday:nth-child(5) { /* Thursday */
        border-color: #2196f3;
        background: rgba(33, 150, 243, 0.1); /* Light blue background */
        color: #2196f3;
      }
      
      .react-calendar__month-view__weekdays__weekday:nth-child(6) { /* Friday */
        border-color: #673ab7;
        background: rgba(103, 58, 183, 0.1); /* Light purple background */
        color: #673ab7;
      }
      
      .react-calendar__month-view__weekdays__weekday:nth-child(7) { /* Saturday */
        border-color: #e91e63;
        background: rgba(233, 30, 99, 0.1); /* Light pink background */
        color: #e91e63;
      }
      
      /* Add hover effects to weekday headers */
      .react-calendar__month-view__weekdays__weekday:hover {
        transform: scale(1.1);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      }
      