.loginmain {
    display: flex;
    justify-content: center;
    align-items: center;
  
    height: 100vh;
     
}



.imgpic {
 
    background-size: cover; 
    background-repeat: no-repeat;
    background-position: center; 
    width: 100%;
    height: 100%; 

}

.over{
    height: auto;
    overflow: hidden;
    width: 100%;
   
}

.submit {
    display: flex;
    justify-content: center;
    align-items: center;
  
    margin-top: 20px;
}


.formcard {

    background: transparent;
    backdrop-filter: blur(25px);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;

}

.fullimg {
   
    background-color: white;
    background-size: 100% 100%; 
    background-repeat: no-repeat; 
    background-position: center;
    width: 100%; 
    height: 100%;
}


.login_txt{
    color: black;
}


@media (max-width: 767px) {
   
  }

  
  @media (min-width: 768px) and (max-width: 991px) {
   
  }

  @media (min-width: 992px) and (max-width: 1199px) {
   
  }

  @media (min-width: 1200px) {
   

    .formcard{
        
        width: 40%;
    }

  }
  

  .login_container{
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100vh;
}

.sub_btn{
    background: linear-gradient(to bottom, #c80000c0, #7A0000);
    width: 100%;
}

.icon_container{
    background: #F7F7F7;
}