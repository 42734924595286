.image{
    width: 140px;
    height: 130px;
margin-left: 48px;
}
.maincard{
margin: 0px 0xp 0px 30px;
}
.staffdetaile{
   background-color: #253544;
   color: white;
   padding: 10px 10px;
}
.detaile{
    background-color: #F2F2F2;
    color: black;
    padding: 10px 10px;
    margin-top: -10px;
}
#userimage{
 font-size: 100px;
 text-align: center;
 margin-top: 10px;
 border-radius: 10px;
}
.Pendingtask{
    background-color: #253544;
    color: white;
    padding: 10px 10px;
    text-align: center;
    border-radius:5px;
}
.datas{
    text-align: center;
}