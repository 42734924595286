/* .bg-success {
    background-color: #dc3545 !important;
  }
  
  .bg-danger {
    background-color: #6c757d !important;
  }
  
  .bg-secondary {
    background-color: #6c757d !important;
  }
  


   */

/* 
body {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  height: 100vh;
} */

.containerside {
  padding: 1px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginate {
  /* padding: 32px 64px; */
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  /* border-radius: 64px; */
  /* border: 4px solid rgb(255 222 210); */
}

/* .paginate-details {
  margin-top: 16px;
  font-weight: 500;
  text-align: center;
} */

button {
  background-color: transparent;
  border: none;
}

/* .nextBtn {
  margin-left: 32px;
} */

/* .nextBtn {
  margin-right: 32px;
} */

.prevBtn,
.nextBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #2a3b52;
}

.nextBtn-icon,
.prevBtn-icon {
  display: flex;
}
/* 
.prevBtn svg {
  margin-right: 6px;
} */

/* .nextBtn svg {
  margin-left: 6px;
} */

/* .prevBtn svg,
.nextBtn svg {
  color: #2a3b52;
} */

button[disabled].prevBtn,
button[disabled].nextBtn {
  cursor: not-allowed;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.containerBtns {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.leftContainer {
  display: flex;
  width: 190px;
  justify-content: end;
}

.rightContainer {
  display: flex;
  width: 190px;
  justify-content: start;
}

.activeBtn {
  margin: 0 12px;
  /* padding: 4px 6px; */
  min-width: 40px;
  /* height: 40px; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5046e5;
  font-size: 14px;
  color: #fff;
  /* border-radius: 50%; */
  /* border: 4px solid #c9c6ff; */
}

.numberBtn {
  cursor: pointer;
  margin: 0 4px;
  min-width: 40px;
  padding: 4px;
  font-size: 18px;
}

.numberBtn[disabled] {
  cursor: not-allowed;
}
