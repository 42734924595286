
.btn-excel2 {
    background-color: #6c757d;
    color: white;
    transition: background-color 0.3s ease;
  }
  
  .btn-excel2:hover {
    background-color: #0D6EFD; 
    color: white;
  }
  

  
.btn-success2 {
    background-color: #6c757d;
    color: white;
    transition: background-color 0.3s ease;
  }
  
  .btn-success2:hover {
    background-color: #198754; 
    color: white;
  }
  

  
.btn-secondary2 {
    background-color: #6c757d;
    color: white;
    transition: background-color 0.3s ease;
  }
  
  .btn-secondary2:hover {
    background-color: #6C757D; 
    color: white;
  }
  

  
.btn-info2 {
    background-color: #6c757d;
    color: white;
    transition: background-color 0.3s ease;
  }
  
  .btn-info2:hover {
    background-color: #0DCAF0; 
    color: white;
  }
  

  
.btn-warning2 {
    background-color: #6c757d;
    color: white;
    transition: background-color 0.3s ease;
  }
  
  .btn-warning2:hover {
    background-color: #FFC107; 
    color: white;
  }
  