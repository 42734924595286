/*----------------------- roles start------------------- */

.admin-roles-main h1 {

    font-size: 30px;
    font-weight: 600;
    color: black;
}

.admin-roles-main h2 {
    font-size: 25px;
    font-weight: 600;
}


.card-container {
    width: 100%;
    overflow: hidden;
}

.admin-role-card {
    width: 100%;
    border-collapse: collapse;
}

.admin-role-card th {
    padding: 7px 50px;
    border: none;
}

.role-data-name {
    padding: 7px 35px;
}

.role-data-all {
    padding: 7px 47px;
}

.role-data-add {
    padding: 7px 65px;
}

.role-data-edit {
    padding: 7px 60px;
}

.role-data-delete {
    padding: 7px 60px;
}

.role-data-view {
    padding: 7px 65px;
}

.role-data-download {
    padding: 7px 80px;
}

.admin-role-card th {
    background-color: #f2f2f2;
}

.admin-role-card tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
}

.admin-role-card tr:hover {
    background-color: #e6e6e6;
}

@media (min-width:992px) and (max-width:1199px) {
    .card-container {
        width: 100%;
    }

    .admin-role-card th {
        padding: 7px 30px;
        border: none;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .card-container {
        width: 100%;
    }

    .admin-role-card th {
        padding: 7px 20px;
        border: none;
    }
}

@media (max-width:767px) {
    .card-container {
        width: 100%;
        overflow-x: auto;
    }

    .admin-role-card th {
        padding: 7px 10px;
        border: none;
    }
}

.tops{
    margin-top: -60px;
    overflow: hidden;
}
/*----------------------- roles End------------------- */