body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* @font-face {
  font-family: 'cambria_font';
  src: url('./assets/fonts/Cambria_Font.ttf') format('ttf');
  font-weight: bold;
  font-style: normal;
}

body{
  font-family: 'cambria_font',sans-serif;
} */

@font-face {
  font-family: 'cambria_font';
  src: url('./assets/fonts/Cambria_Font.ttf') format('truetype'); /* Corrected format */
  /* font-weight: bold; */
  font-style: normal;
}

body  {
  font-family: 'cambria_font', sans-serif; 
  font-size: 16px;
  /* Fallback to sans-serif if custom font fails */
}

/* You can still style specific elements differently if needed */
h1, h2, h3, h4, h5, h6  {
  font-family: 'cambria_font', sans-serif;
  font-size: 16px;
}

p, span, div, th  {
  font-family: 'cambria_font', sans-serif;
  font-size: 17px;
}
td{
  font-family: 'cambria_font', sans-serif;
  font-size: 18px;
}

body {
  font-family: 'cambria_font', 'Arial', 'Helvetica', sans-serif;
  font-size: 16px;
}