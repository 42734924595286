.name {
  margin-left: 65px;
  margin-top: -40px;
  color: rgb(44, 44, 44);
}

.names {
  margin-left: 100px;
  margin-top: -25px;
}

.spacer {
  margin-left: 20px;
}

.chart-container {
  width: 500px;
  height: 250px;
}


.charts-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.chart-wrapper {
  flex: 1 1 30%;
  padding: 10px;
  box-sizing: border-box;
  min-width: 300px;
  max-width: 320px;
}

canvas {
  width: 100% !important;
  height: 200px !important;
}

.one {
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}

.two {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  background-color: lightblue;
  color: white;
}

.charted {
  background: rgba(140, 50, 243, 0.858);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.custom-card {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.custom-card-header {
  background: linear-gradient(135deg, #6a11cb, #2575fc);
  color: white;
  padding: 15px;
  font-weight: bold;
  text-align: center;
}
.custom-table {
  border-radius: 8px;
  overflow: hidden;
  border-collapse: separate;
  border-spacing: 0;
}
.custom-table thead {
  background-color: #f7f9fc;
}
.custom-table th,
.custom-table td {
  padding: 12px 15px;
  text-align: left;
}
.custom-table tbody tr {
  transition: background-color 0.3s;
}
.custom-table tbody tr:hover {
  background-color: #f1f5f9;
}
.custom-table th {
  background-color: #e0e4e8;
  font-weight: bold;
}

.project {
  background: linear-gradient(to bottom, #c80000c0, #7a0000);
}

.projects {
  background: linear-gradient(to bottom, #c80000c0, #7a0000);
}

.content {
  font-size: 15px;
}
.charts {
  height: 40px;
}
.chart {
  height: 320px;
  width: 100%;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border: none;
}
.year {
  margin-left: 370px;
  margin-bottom: 10px;
}
.years {
  margin-left: 370px;
  margin-bottom: 10px;
}
.yearbut {
  font-size: 13px;
}
.butmain {
  margin-left: 90px;
}
.projectbut {
  height: 35px;
  width: max-content;
  align-items: center;
}

.dashboard {
  margin-top: -80px;
}

.iconcards {
  margin-left: -2px;
}

.hello {
  background: linear-gradient(to bottom, #c80000c0, #7a0000);
}

.sixmonth {
  color: #cf7272;
  font-size: 17px;
}

.studentcard {
  padding-left: 3px;
}
.salescard {
  padding-left: 3px;
}

.piechart {
  margin-left: -5px;
}
