.photo{
    border: 1px solid black;
    height: 100px;
    width: 220px;
    color: white;
    margin-top: -15px;
   
}



.background{
    
     padding: 15px;
     background-color: white;
}

.para{

 
    justify-content: space-evenly;
    word-spacing:normal;
     color: white;
}

.text{
    font-size: 40px;
}

.detailss{
   margin-top: 15px;
   line-height: 15px;
   padding: 10px;

}

.line {
    text-decoration:underline;
    justify-content: center;
    display: flex;
}

.bill{
    text-decoration:underline;
}

#border{
border: 2px solid black;
height: 100%;

}

.data-2{
     margin-top: 15px;
     line-height: 6px;
}


.voice{
    line-height: 1px;
}

.account{
    line-height: 15px;
}

.amount{
    margin-top: 20px;
    line-height: 25px;
}

.total{
    margin-top: 20px;
    line-height: 15px;
}

.start{
    margin-top: 5px;
}

.alan{
   font-size: 30px;
    font-weight: bold;
    margin-top: 10px;
    
}

.overall{
    background-color: rgb(24, 122, 197);
    border-radius: 0px 0px 2px 2px;
}


.techsoft{
    
    padding: 10px;
  
}
