
.main_border{
    border: 1px solid black;
    /* margin: 10px; */
    padding:5px 30px 30px 30px;
}
.academy_invoice{
    height: 25px;
}
.skimitar_heading{
    text-align: center;
    color: #B10000;
    font-size: 34px;
}
.main_branch{
    display: flex;
    justify-content: space-between;
   height: 80px;
}
.sub_branch{
    /* border-right: 1px solid black;
    padding-right:0px; */
    width: 700px;
}
.relationship_main{
    padding-left: 20px;
}
.student_invoice_cont{
    border-right: 1px solid black;
    padding-right:25px;
    /* width: 400px; */
}
.payment_head{
    width: 430px;
    border-right: 1px solid black;
    padding-right:25px;
}
.branch_code{
    display: flex;
    line-height: 2px;
    font-size: 13px;
}
.branch_length{
width: 90px;
font-size: 13px;
}
.sub_branch{
    width: 270px;
}
.student_invoice_cont{
    width: 300px;
}
.invoice_data_main{
    width: 320px;
    padding-left: 20px;
}
.logoimage{
    width: 150px;
    height: 40px;
}
.logo_main{
    padding-top:20px;
    display: flex;
    justify-content: center;
    width: 250px;
}
.invoice_details{
    width: 300px;
    text-align: center;
}
.student_main{
    display: flex;
    justify-content: space-between;
    height: 30px;
    margin-top: -10px;
}
.payment_main_head{
    margin-top: -15px;
}
/* //////student and invoice */

.student_invoice{
    display:flex;
    justify-content: space-between;
}
/* /// student*/
.student_data_main{
    width: 230px;
    display: flex;
    justify-content: space-between;
    line-height: 2px;
    font-size: 12px;
}
.student_data{
    width: 150px;
    text-align: right;
}
.student_data_main_address{
    /* padding-top: 2px; */
    text-align: right;
    height: 37px;
    width: 230px;
    display: flex;
    justify-content: space-between;
    line-height: 2px;
}
/* /////invoice */
.invoice_data_main{
   
    display: flex;
    justify-content: space-evenly;
    line-height: 2px;
}
.invoice{
    width: 200px;
}
.invoice_data{
    width: 130px;
    text-align: right;
    padding-right: 40px;
}

/* /// */
.payment_main{
    display: flex;
    justify-content: space-between;
}
.Person_name{
    width: 230px;
    display: flex;
    justify-content: space-between;
    line-height: 2px;
}
.contact_name{
    /* height: 85px; */
    /* width:390px; */
    display: flex;
    padding-top: 5px;
    justify-content: space-between;
}
.Relationship{
    width: 190px;
    line-height: 2px;
}
.contact_data{
    width: 90px;
    line-height: 2px;
}
/* //// */
.sno_payment{
    display: flex;
    margin-top: -10px;
}
/* .para{
    margin-top: -10px;
} */
.sno{
    width: 350px;
}
.description{
    width: 100px;
    border-right: 1px solid black;
    padding-right:0px;
    line-height: 2px;
}
.payment{
    width: 100px;
    text-align: center;
    line-height: 2px;
}
.line{
 margin-top: 20px;
    border: 1px solid black;
  
}
.line_fr{
    margin-top: 4px;
    border: 1px solid black;
}
.line_fr{
    margin-top: 8px;
    border: 1px solid black;
}
.main_table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}
.main_heading_data{
    height: 10px;

}
.main_head, .main_data_table {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    text-align: center;
    font-size: 13px;
}

.main_head {
  text-align: center;
    background-color: #f4f4f4;
    font-weight: bold;
}
.table_Description{
    width: 300px;
}
.declaration{
    width: 390px;
    /* border-right: 1px solid black;
    padding-right:50px; */
    line-height: 15px;
}
.contact_main{
    display: flex;
    justify-content: space-between;
}
.Contact{
    display: flex;
    line-height: 2px;
}
.thanks_main{
    display: flex;
}
.main_dec{
 
    border-right: 1px solid black;

}
.sig_main{
    width: 390px;
    /* border-right: 1px solid black;
    padding-right:50px; */
}
.contact_heading{
    width: 150px;
}
.seal_sig{
    width: 150px;
    padding-top: 100px;
    padding-left: 20px;
   
}
.contact_data_last{
    color: #B10000;
}
.sig_cont{
    color: #B10000;
}
.Thanks{
    text-align: center;
}
.fonts{
    font-size: 12px;
}
.fonts_head{
    font-size: 13px;
}