.updelete {
  color: rgb(0, 0, 0);
}

.updelete:hover {
  color: white;
  background-color: rgb(16, 75, 202);
}

.delete {
  color: rgb(19, 19, 19);
}

.delete:hover {
  background-color: rgb(71, 71, 71);
  color: white;
}

.Print {
  color: rgb(0, 0, 0);
}

.Print:hover {
  color: white;
  background-color: rgb(0, 141, 42);
}

.custom-modal .modal-dialog {
  max-width: 50%;
}

.top {
  margin-top: -50px;
}

.form-group {
  margin-bottom: 20px;
  position: relative;
}

.floating-label {
  position: relative;
  margin-bottom: 20px;
}

.floating-label input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  font-size: 16px;
  transition: all 0.3s ease;
}

.floating-label label {
  position: absolute;
  top: 10px;
  left: 10px;
  background: white;
  padding: 0 5px;
  font-size: 16px;
  color: #999;
  pointer-events: none;
  transition: all 0.3s ease;
}

.floating-label input:focus+label,
.floating-label input:not(:placeholder-shown)+label,
.floating-label label.filled {
  top: -12px;
  left: 10px;
  font-size: 12px;
  color: #000;
}

.green-label {
  font-size: 13px;
  padding: 0px 3px;
  width: 80px;
  background-color: green;
  border-radius: 4px;
  font-weight: 600;
  text-transform: capitalize;
}

.red-label {
  font-size: 13px;
  padding: 0px 3px;
  width: 80px;
  background-color: red;
  font-weight: 600;
  border-radius: 4px;
  text-transform: capitalize;
}

.online {
  font-size: 13px;
  /* padding: 0px 3px; */
  width: 80px;
  color: green;
  /* Green for true */
  border-radius: 4px;
  font-weight: 600;
  text-transform: capitalize;
}

.offline {
  font-size: 13px;
  /* padding: 0px 3px; */
  width: 80px;
  color: red;
  /* Red for false */
  font-weight: 600;
  border-radius: 4px;
  text-transform: capitalize;
}

.yellow-label {
  font-size: 13px;
  padding: 0px 3px;
  width: 80px;
  background-color: rgb(225, 228, 43);
  font-weight: 600;
  border-radius: 4px;
  text-transform: capitalize;
}

.bg-danger1 {
  background-color: #dc3545;
}

.bg-secondary1 {
  background-color: #6c757d;
}

.bg-warning1 {
  background-color: #ffc107;
}

.bg-primary1 {
  background-color: #007bff;
}

.bg-success1 {
  background-color: #28a745;
}

.bg-dark1 {
  background-color: #343a40;
}

.bg-info1 {
  background-color: #17a2b8;
}

.text-white1 {
  color: white;
  font-size: 13px;
  padding: 0px 3px;
  width: 100px;
  border-radius: 4px;
  font-weight: 600;
  text-transform: capitalize;
}

.btncolor {
  background: linear-gradient(to bottom, #c80000c0, #7a0000);
}