.view {
  width: 150px;
}
.modalbutton {
  margin-left: 300px;
}
#dropdown {
  width: 200px;
}
#tablebutton {
  width: 200px;
}
.addmodal .modal-dialog {
  max-width: 550px;
}

.addmodal .modal-content {
  width: 100%;
}
.custom-width {
  width: 50%;
  height: 38px;
}
.custom-heightselect {
  height: 38px;
}
.currenttime {
  font-size: 23px;
}
.time {
  margin-top: -40px;
}

.login-event {
  font-size: 10px;
  text-align: center;
}

.add {
  border-color: #000000;
  background-color: black;
  color: #fff;
  background-position: 100%;
  background-size: 400%;
  transition: background 300ms ease-in-out, transform 300ms ease-in-out;
  width: 60px;
  height: 35px;
}

.add:hover {
  background-position: 0;
  border: 2px solid #8a0000;
  background-image: linear-gradient(to bottom, #c80000c0, #7a0000);
  color: white;
}

.submitbut {
  border-color: #000000;
  background-color: black;
  color: #fff;

  background-position: 100%;
  background-size: 400%;
  transition: background 300ms ease-in-out, transform 300ms ease-in-out;
  width: 80px;
  height: 40px;
}
.submitbut:hover {
  background-position: 0;
  border: 2px solid #8a0000;
  background-image: linear-gradient(to bottom, #c80000c0, #7a0000);
}

.cancelbut {
  border-color: #6c757d; /* Change to your desired color */
  color: #fff;
  background-image: linear-gradient(45deg, white 50%, transparent 50%);
  background-position: 100%;
  background-size: 400%;
  transition: background 300ms ease-in-out, transform 300ms ease-in-out;
  width: 80px;
  height: 40px;
}
.cancelbut:hover {
  background-position: 0;
  color: #6c757d;
  border: 2px solid #6c757d;
}
