.mainsection{
    overflow-x: hidden;
    margin-top: -65px;
}
.head{
    text-align: center;
    font-size:larger;
    font-weight:bold;
 }
 
 
 
 .invoice_card{
 
     margin-top: 10px;
     margin-bottom: 5px;
     padding: 10px;
 }
 
 
 .first{
     margin-top: 5px;
 }
 
 
 .side{
     display: flex;
     justify-content: center;
 }
 
 
 .invoice-button{
 
     word-spacing: 10px;
     margin-left: -50px;
    font-size: 14px;
 
   
   
 }
 
 .Add{
     margin-top: 25px;
     font-size: 14px;
 }
 
 
 .paytype{
     width: 80%;
 }
 
 
 
 .gap{
     margin-top: -65px;
     font-size: 12px;
 } 