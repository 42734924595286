.Submitbut{

    border-color: #000000;
    background-color: black;
    color: #fff;
    
    border-radius: 5px;
    background-position: 100%;
    background-size: 400%;
    transition: background 300ms ease-in-out, transform 300ms ease-in-out;
    width: 80px;
    height: 40px; 
 

}
.Submitbut:hover {
    background-position: 0;
    border: 2px solid #8a0000;
    background-image: linear-gradient(to bottom, #c80000c0, #7A0000);
}






.Cancelbut{
    border-color: #6C757D; 
    color: #ffff;
    background-color: rgb(117, 117, 117);
     background-image: linear-gradient(45deg, white 50%, transparent 50%); 
    background-position: 100%;
    background-size: 400%;
    border-radius: 5px;
    transition: background 300ms ease-in-out, transform 300ms ease-in-out;
    width: 80px;
    height: 40px; 
}
.Cancelbut:hover {
    background-position: 0;
    color: #141414;
    border: 2px solid #6C757D;
}