.lightblue {
  background-color: #fceded;
}

.details-data {
  background-color: #fceded;
  border-radius: 8px;
  width: 100%;
  margin-left: 0%;
}

.dashBoard_text {
  font-size: 14px;
}

.editdetails {
  color: rgb(180, 177, 177);
}

.editdetailsss {
  color: rgb(80, 80, 80);
  margin-top: -25px;
  margin-left: 185px;
}

.cardborder {
  border-radius: 0px 0px 0px 0px;
}

.carddetails {
  border-radius: 0px 0px 0px 0px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.profileimg {
  width: 100%;
  height: 180px;
  object-position: start;
  object-fit: fill;
  display: block;
}

.profile-details {
  background-color: rgba(204, 6, 6, 0.918);
  padding: 5%;
  border-radius: 8px;
}

.profile-detailsed {
  background-color: rgba(204, 6, 6, 0.918);
  padding: 3%;
  border-radius: 8px;
}

.cardeds {
  border-radius: 6px;
  text-align: center;
}

.imgdetails {
  text-align: center;
  justify-content: center;
}

.marginup {
  margin-top: -80px;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
}
.marginup::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.marginup::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.timeline {
  padding: 2px;
  background-color: rgba(204, 6, 6, 0.918);
}

.Completed-profile {
  height: 25vh;
}

/*   
  .bg-success {
    background-color: #dc3545 !important;
  }
  
  .bg-danger {
    background-color: #6c757d !important;
  }
  
  .bg-secondary {
    background-color: #6c757d !important;
  }

   */
