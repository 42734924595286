* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  /* font-family: Arial, Helvetica, sans-serif; */
  font-family: 'cambria_font', sans-serif;
}

:root {
  scroll-behavior: smooth;
}

body {
  /* font-family: "Open Sans", sans-serif; */
  font-family: 'cambria_font', sans-serif;
  /* background: #E7E7E7; */
  color: #2c2c2c;
}

a {
  color: #ffffff;
  text-decoration: none;
}

a:hover {
  color: #000000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: "Nunito", sans-serif; */
  font-family: 'cambria_font', sans-serif;
  font-weight: bold;
}

/* <-----------Sidebar Style Start----------> */
.container-main {
  display: flex;
  margin-top: 70px;
  /* overflow-y: auto; */
}

main {
  width: 100%;
  padding: 20px;
  min-height: 100vh;
  position: relative;
}

footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

/* Styling the scrollbar for the sidebar */
.sidebar {
  margin-top: -70px;
  background: rgb(255, 255, 255);
  position: relative;
  color: #fff;
  height: 100%;
  width: 200px;
  transition: all 0.5s;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow-x: hidden;
  border-radius: 0px 2px 25px 0px;
  z-index: 1;
}

.sidebar::-webkit-scrollbar {
  width: 8px;
  border-radius: 0px 25px 25px 0px;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 10px;
  border: 2px solid #f2f3f2;
}

.sidebar::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 10px;
}

.sidebar {
  scrollbar-width: thin;
  scrollbar-color: #ffffff #c2c2c2;
}

.icon {
  /* Dark Red */
  color: #d34b4b;
}

/* .sidebar:hover {
  overflow-y: scroll;
} */
/* .main {
  margin-left: 250px;
} */

.isOpen {
  margin-left: 250px;
}

.isClose {
  margin-left: 50px;
}

@media (max-width: 767px) {
  .container-main {
    display: flex;
  }

  .sidebar {
    transition: transform 0.3s ease-in-out;
    margin-left: -150px;
    transform: translateX(-300px);
    position: fixed;
    top: 0;
    /* bottom: 0; */
    left: 0;
    z-index: 1000;
    width: 250px;
  }

  .main {
    transition: margin-left 0.3s ease-in-out;
    margin-left: 0px;
  }

  .container-main.isOpen .sidebar {
    transform: translateX(0);
  }

  .container-main.isOpen .main {
    margin-left: 250px;
  }
}

.top_section {
  display: flex;
  align-items: center;
  padding: 10px 5px;
}

.logo {
  font-size: 20px;
  margin-left: 10px;
  margin-top: 10px;
}

.sidebarusericon {
  font-size: 23px;
}

.bars {
  display: flex;
  font-size: 25px;
  color: black;
  /* margin-left: 50px; */
}

.link {
  display: flex;
  color: rgb(44, 46, 25);

  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
}

.link:hover {
  background: linear-gradient(to bottom, #c80000c0, #7a0000);

  color: rgb(255, 255, 255);
  transition: all 0.5s;
  border-radius: 10px 0px 0px 10px;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */
}

.active {
  position: relative; /* Ensure this is set */
  background: linear-gradient(to bottom, #c80000c0, #7a0000);
  /* background: rgb(42, 103, 236); */

  border-radius: 10px 0px 0px 10px;
  width: 100%;
  margin: 5px;
  color: rgb(255, 255, 255);
  /* color: rgb(255, 255, 255); */
  /* box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px; */
}

/* .active:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  bottom: 40%;
  right: -1%;
 rotate: 270deg;
  border: .75rem solid transparent;
  border-top: none;
  border-bottom-color: var(--mainColor);
  filter: drop-shadow(0 -0.0625rem 0.0625rem rgb(255, 255, 255));
  z-index: 10; 
} */

.icon,
.link_text {
  font-size: 17px;
  font-weight: 300;
}

#icons {
  transform: rotate(90deg);
  /* Rotate the icon 90 degrees */
}

.submenu-admin {
  padding: 4px;
}

.icon-admin {
  font-size: 12px;
  /* color: rgb(192, 69, 69); */
}

.admincont {
  margin-left: 2px;
  color: rgb(10, 10, 10);
  width: 80px;
}

/*<----------- Sidebar Style End-------------> */
